import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
    return (
        <>
            <div className="boxed_wrapper ltr">
                {/*Start breadcrumb area paroller*/}
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url(assets/images/breadcrumb/breadcrumb-1.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <div className="title" data-aos="fade-up" data-aos-easing="linear" data-aos-duration={1500}>
                                        <h2>Error Page</h2>
                                    </div>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="active">404</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*End breadcrumb area*/}
                {/*Start Error Page Area*/}
                <section className="error-page-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="error-content text-center">
                                    <div className="big-title wow fadeInDown" data-wow-delay="100ms" data-wow-duration="1500ms">
                                        <h2>Oh...ho...</h2>
                                    </div>
                                    <div className="title wow fadeInDown" data-wow-delay="100ms" data-wow-duration="1500ms">
                                        <h2>Sorry, Something Went Wrong.</h2>
                                    </div>
                                    <div className="text">
                                        <p>The page you are looking for was moved, removed, renamed<br /> or never existed.</p>
                                    </div>
                                    {/* <div className="error-page-search-box">
                                        <form className="search-form" action="#">
                                            <input placeholder="Search ..." type="text" />
                                            <button type="submit"><i className="fa fa-search" aria-hidden="true" /></button>
                                        </form>
                                    </div> */}
                                    <div className="btns-box wow slideInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                        <Link className="btn-one" to="/">
                                            <span  className="txt">Back to Home<i className="icon-refresh arrow" /></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
           
          
            </div>

        </>
    )
}

export default Error