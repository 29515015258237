import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import swal from "sweetalert";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { IoLocation, IoLogOut } from 'react-icons/io5';
import { TbArrowRoundaboutRight } from "react-icons/tb";
import { RiContactsBookFill } from "react-icons/ri";
import { MdMiscellaneousServices } from "react-icons/md";
import { MdPhoneCallback } from "react-icons/md";


const Navbar = () => {

    let navigate = useNavigate()
    const [visible, setVisible] = useState(false);

    let closesidebar = (path) => {
        setVisible(false)
        navigate(`/${path}`)
    }


    let logout_handel = (path) => {
        swal({
            title: "Are you sure?",
            text: "You want to logout",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            // content: {
            //     element: "input",
            //     attributes: {
            //         placeholder: "Enter your reason for deletion",
            //         type: "text",
            //     },
            // },
        }).then((willDelete) => {
            if (willDelete) {
                userlogout();
            } else {
            }
        });
        let userlogout = () => {
            setVisible(false)
            navigate("/")

        };
    };

    return (
        <>
            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                position="right"  // Sets sidebar to open from the right
                content={({ closeIconRef, hide }) => (
                    <>
                        <div className="">
                            <div className="view-all-service">
                                <ul className="service-pages">
                                    <li className='active'><a onClick={() => closesidebar("")}> <img width={"100px"} src="./logo/logo.png" alt="" /> <Button type="button" ref={closeIconRef} onClick={(e) => hide(e)} icon="pi pi-times" rounded outlined className="h-2rem w-2rem" style={{ float: "right", fontSize: "20px", marginTop: "15px", border: "1px solid red", borderRadius: "20px" }}></Button></a> </li>
                                    <li><a onClick={() => closesidebar("about_us")}>About us <TbArrowRoundaboutRight style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /></a> </li>
                                    <li><a onClick={() => closesidebar("services")}>Services <RiContactsBookFill style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /> </a></li>
                                    <li><a onClick={() => closesidebar("contact_us")}>Contact <MdMiscellaneousServices style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /> </a></li>
                                    {/* <li><a onClick={logout_handel}>Logout <IoLogOut style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /></a></li> */}
                                    <div className="header-button-style1 text-center mt-3 ml-0">
                                        <a onClick={() => closesidebar("userlogin")} style={{ color: "white" }} className="btn-one">
                                            <span className="txt">User Login</span>
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </div>

                    </>
                )}
            ></Sidebar>
            {/* <div className="boxed_wrapper ltr"> */}
            <header className="main-header header-style-one">
                {/*Start Header Top*/}
                <div className="header-top">
                    <div className="auto-container">
                        <div className="outer-box">
                            <div className="header-top__left">
                                <div className="main-logo-box">
                                    <Link to="/">
                                        <img style={{ width: "120px" }} src="./logo/logo.png" />
                                    </Link>
                                </div>
                            </div>
                            <div className="header-top__right">
                                <div className="header-contact-info-style1">
                                    <ul>
                                        <li>

                                            <div className="icon">
                                                <span className="icon-telephone" />
                                            </div>
                                            <div className="text">
                                                <p>Call anytime</p>
                                                <h5><a href="tel:123456789">+91 70020 67082 </a></h5>
                                            </div>

                                        </li>
                                        <li>
                                            <div className="icon">
                                                <span className="icon-email" />
                                            </div>
                                            <div className="text">
                                                <p>Write email</p>
                                                <h5><a href="mailto:yourmail@email.com">ambuready@gmail.com</a></h5>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="header-button-style1">
                                    <Link className="btn-one" to="/userlogin">
                                        <span className="txt">Login</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Start Header*/}
                <div className="header">
                    <div className="auto-container">
                        <div className="outer-box">
                            {/*Start Header Left*/}
                            <div className="header-left">
                                <div className="nav-outer style1 clearfix">
                                    {/*Mobile Navigation Toggler*/}
                                    <div className="mobile-nav-toggler" onClick={() => setVisible(true)}>
                                        <div className="inner">
                                            <span className="icon-bar" />
                                            <span className="icon-bar" />
                                            <span className="icon-bar" />
                                        </div>
                                    </div>
                                    {/* Main Menu */}
                                    <nav className="main-menu style1 navbar-expand-md navbar-light">
                                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul className="navigation clearfix">
                                                <li className="dropdown current"><a href="#">Home</a>
                                                    <ul>
                                                        <li><a href="#">Home Page 01</a></li>
                                                        <li><a href="#">Home Page 02</a></li>
                                                        <li><a href="#">Home OnePage</a></li>
                                                        <li><a href="#">RTL Home</a></li>
                                                        <li className="dropdown"><a href="#">Header Styles</a>
                                                            <ul>
                                                                <li><a href="#">Header Style One</a></li>
                                                                <li><a href="#">Header Style Two</a></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </div>
                                    </nav>
                                    {/* Main Menu End*/}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/*End Header Top*/}

                {/*Start Header*/}
                <div className="header">
                    <div className="auto-container">
                        <div className="outer-box">
                            {/*Start Header Left*/}
                            <div className="header-left">
                                <div className="nav-outer style1 clearfix">
                                    {/*Mobile Navigation Toggler*/}
                                    <div className="mobile-nav-toggler" onClick={() => setVisible(true)}>
                                        <div className="inner">
                                            <span className="icon-bar" />
                                            <span className="icon-bar" />
                                            <span className="icon-bar" />
                                        </div>
                                    </div>
                                    {/* Main Menu */}
                                    <nav className="main-menu style1 navbar-expand-md navbar-light">
                                        <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                            <ul className="navigation clearfix">
                                                <li><Link to="/">Home</Link>
                                                </li>
                                                <li><Link to="/about_us">About us</Link>
                                                </li>
                                                <li ><Link to="/services">Services</Link>
                                                </li>
                                                {/* <li className="dropdown"><a href="#">Pages</a>
                                                    <ul>
                                                        <li><Link to="/privacy">Privacy Policy</Link></li>
                                                        <li><Link to="/terms_Conditions" >Terms and Conditions</Link></li>
                                                       
                                                    </ul>
                                                </li> */}
                                                <li><Link to="/contact_us">Contact</Link></li>
                                            </ul>
                                        </div>
                                    </nav>
                                    {/* Main Menu End*/}
                                </div>
                            </div>
                            {/*End Header Left*/}
                            {/*Start Header Right*/}
                            <div className="header-right">
                                <div className="header-social-link">
                                    <ul className="clearfix">
                                        <li>
                                            <a href="#" style={{ width: "40px", height: "40px" }}><i className="icon-twitter" /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/people/AmbuReady/61550938978127/?mibextid=LQQJ4d" style={{ width: "40px", height: "40px" }}><i className="icon-facebook" /></a>
                                        </li>
                                        <li>
                                            <a href="#" style={{ width: "40px", height: "40px" }}><i className="icon-pinterest" /></a>
                                        </li>
                                        <li>
                                            <a href="#" style={{ width: "40px", height: "40px" }}><i className="icon-instagram" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/*End Header Right*/}
                        </div>
                    </div>
                </div>
                {/*End header*/}

                {/*Sticky Header*/}
                <div className="sticky-header">
                    <div className="container">
                        <div className="clearfix">
                            {/*Logo*/}
                            <div className="logo float-left">
                                <Link to="/" className="img-responsive">
                                    <img style={{ width: "120px" }} src="./logo/logo.png" alt="Awesome Logo" title />
                                </Link>
                            </div>
                            {/*Right Col*/}
                            <div className="right-col float-right">
                                {/* Main Menu */}
                                <nav className="main-menu clearfix">
                                    <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li><Link to="/">Home</Link>
                                            </li>
                                            <li><Link to="/about_us">About us</Link>
                                            </li>
                                            <li ><Link to="/services">Services</Link>
                                            </li>
                                            {/* <li className="dropdown"><a href="#">Pages</a>
                                                <ul>
                                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                                    <li><Link to="/terms_Conditions" >Terms and Conditions</Link></li>
                                                   
                                                </ul>
                                            </li> */}
                                            <li><Link to="/contact_us">Contact</Link></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </header>
            {/* </div> */}


            <div className="d-block d-md-none fixed-bottom">
                <div className="mb-2 ml-3 mr-3">
                    <a className="btn btn-one wow animate__animated animate__fadeInUp mt-2" style={{ width: "100%" }} href="tel:+917002067082">
                        <span style={{ color: "white", width: "100%", display: "block" }} className="txt" >
                            <MdPhoneCallback style={{ fontSize: "30px", marginRight: "5px" }} />  Emergency
                            {/* <i className="icon-refresh arrow" /> */}
                        </span>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Navbar