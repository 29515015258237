import React, { useEffect, useState } from 'react';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import swal from "sweetalert";
import secureLocalStorage from 'react-secure-storage';

const Map = () => {
    let navigate = useNavigate()
    let User_Id = secureLocalStorage.getItem("User_Id")
    let [loaderbtn, setloaderbtn] = useState(false)


    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);

    let updateloaditon_handel = (e) => {
        e.preventDefault(); // Fix the typo here
        let form = e.target
        let formdata = new FormData()
        formdata.append("user_id", User_Id)
        formdata.append("lat", latitude)
        formdata.append("lng", longitude)

        console.log(User_Id)

        setloaderbtn(true)
        axios.post(`${process.env.REACT_APP_API_KEY}ambulance_booking_completed`, formdata).then((res) => {
            console.log(res)
            if (res.data.result == "true") {
                setloaderbtn(false)
                swal({
                    title: "Success!",
                    text: "Location Update Successfully",
                    icon: "success",
                    button: "OK",
                }).then(() => {
                    form.reset();  // Reset the form
                });

            } else {
                toast.error(res.data.msg)
                setloaderbtn(false)

            }
        }).catch((err) => {
            console.log(err)
            setloaderbtn(false)
        })
    }

    useEffect(() => {
        const loadMapScript = () => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCGjR_PJHtFewHUhrwRiiVJFkn5_O4e7Yc&libraries=places`;
            script.async = true;
            script.onload = initMap; // Initialize the map after the script loads
            document.body.appendChild(script);
        };

        loadMapScript(); // Load the map script on mount
    }, [latitude, longitude]); // Only run once when the component mounts

    const initMap = () => {
        const map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: latitude, lng: longitude }, // Initially set to 0, 0 or current coordinates
            zoom: 8,
        });

        const input = document.getElementById('search-input');
        const autocomplete = new window.google.maps.places.Autocomplete(input, {
            componentRestrictions: { country: 'in' }, // Restrict to India
        });
        autocomplete.bindTo('bounds', map);

        const marker = new window.google.maps.Marker({
            map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            position: { lat: latitude, lng: longitude },
        });

        window.google.maps.event.addListener(marker, 'dragend', (event) => {
            setLatitude(event.latLng.lat());
            setLongitude(event.latLng.lng());
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }
            marker.setPosition(place.geometry.location);
            setLatitude(place.geometry.location.lat());
            setLongitude(place.geometry.location.lng());
        });
    };

    return (
        <>
            <Toaster />

            <div className="filter-search-form relative filter-border" style={{ padding: '20px' }}>
                {/* Search Input */}
                <form onSubmit={updateloaditon_handel} className="default-form2" >
                    <IconField iconPosition="right">
                        <InputIcon className="pi pi-map-marker" style={{ top: "15px" }} />
                        <InputText
                            id="search-input"
                            className="px-3 py-2"
                            required
                            placeholder="Search for a location"
                            style={{ width: "100%", fontSize: "16px", border: "1px solid gray", marginBottom: '10px' }}
                        />
                    </IconField>

                    <div className="row">
                        <div className="col-xl-12 text-center">
                            <div className="button-box">
                                {loaderbtn == true ? (
                                    <div class="spinner-border text-danger" role="status" style={{ width: "3rem", height: "3rem" }}>
                                        <span class="sr-only"></span>
                                    </div>
                                ) : (
                                    <button className="btn-one" type="submit" data-loading-text="Please wait..."><span className="txt">
                                        Update</span></button>

                                )}

                            </div>
                        </div>
                    </div>

                </form>

                {/* Map */}
                <div
                    id="map"
                    style={{
                        // width: '100%',
                        // height: '500px',
                        display: "none",
                        border: '1px solid gray',
                        marginTop: '10px',
                    }}
                ></div>
            </div>
        </>
    );
};

export default Map;
