import React from 'react'
import { Link } from 'react-router-dom'

const Services = () => {
    return (
        <>
            <div className="boxed_wrapper ltr">
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url(catelogo/ambulance-rescue-emergency-blue-light.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                <h2 className='text-white'>Service</h2>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="active">Service</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features-style1-area mt-5 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="features-style1__content">
                                    <ul>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    <img width={"70%"} src="./catelogo/emer-response.png" alt="" />
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Emergency Medical Transportation</a></h3>
                                                    <p style={{ textAlign: "justify" }}>In delivering fast and reliable emergency medical transportation, we prioritize individuals in urgent need of medical care. Our ambulances boast state-of-the-art equipment, guaranteeing the highest level of care during transportation. Count on us for swift, efficient, and expertly equipped emergency medical services, tailored to your needs and concerns .</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    <img width={"70%"} src="./catelogo/emergency-medical.png" alt="" />
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Non-Emergency Medical Transportation</a></h3>
                                                    <p style={{ textAlign: "justify" }}>Our non-emergency medical transportation services cater to individuals needing routine medical transport for appointments or transfers between healthcare facilities. We prioritize comfort, reliability, and punctuality, ensuring a stress-free experience. Our trained staff and well-equipped vehicles guarantee a safe and secure journey for your medical needs .</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    <img width={"70%"} src="./catelogo/medical-support-service.png" alt="" />
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Oxygen Cylinder-Ready Ambulance</a></h3>
                                                    <p style={{ textAlign: "justify" }}>Our oxygen-equipped ambulance services deliver critical medical care for patients requiring oxygen support during transportation. Manned by skilled medical professionals, we prioritize patient safety and comfort, offering essential medical attention. Count on us for reliable emergency services that ensure your well-being during transit, addressing your specific medical needs with expertise .</p>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    <ul>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    {/* <div className="box" /> */}
                                                    <img width={"70%"} src="./catelogo/emer-response.png" alt="" />
                                                    {/* <span className="icon-clinic" /> */}
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Oxygen Cylinder-Ready Ambulance</a></h3>
                                                    <p style={{ textAlign: "justify" }}>Our oxygen-equipped ambulance services deliver critical medical care for patients requiring oxygen support during transportation. Manned by skilled medical professionals, we prioritize patient safety and comfort, offering essential medical attention. Count on us for reliable emergency services that ensure your well-being during transit, addressing your specific medical needs with expertise .</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    <img width={"70%"} src="./catelogo/emergency-medical.png" alt="" />
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Mini Ambulance Service</a></h3>
                                                    <p style={{ textAlign: "justify" }}>Our mini ambulance services are ideal for providing medical assistance in narrow or hard-to-reach areas where regular ambulances cannot access. These vehicles are equipped with essential medical equipment and staffed by trained medical professionals who can quickly respond to medical emergencies in these areas .</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    <img width={"70%"} src="./catelogo/medical-support-service.png" alt="" />
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Air Ambulance Service</a></h3>
                                                    <p style={{ textAlign: "justify" }}> Air Ambulance Services provide swift emergency medical transport via aircraft. Equipped with advanced medical facilities and staffed by highly trained professionals, they ensure rapid transfer of critically ill or injured individuals to medical facilities, especially where ground transportation is impractical or time-sensitive .</p>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>

                                    <ul>
                                        <li>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center">
                                                    {/* <div className="box" /> */}
                                                    <img width={"70%"} src="./catelogo/train-ambulance.png" alt="" />
                                                    {/* <span className="icon-clinic" /> */}
                                                </div>
                                                <div className="text-holder">
                                                    <h3><a href="#">Train Ambulance Service</a></h3>
                                                    <p style={{ textAlign: "justify" }}>Our Train Ambulance Service provides rapid and efficient emergency medical transport. Staffed by skilled professionals and equipped with advanced medical technology, we ensure swift transfer of critically ill individuals to medical facilities. Our priority is timely response and specialized care, addressing the urgency of medical situations .</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>



                                {/* <li className='px-0' style={{ width: "185px" }}>
                                        <a href="#" className='text-center'>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center" style={{ height: "40px", width: "100%" }}>
                                                    <img width={"25%"} src="./catelogo/emergency-medical.png" alt="" />
                                                </div>

                                            </div>
                                            <p>
                                                Non-Emergency
                                            </p>
                                        </a>
                                    </li>
                                    <li className='px-0' style={{ width: "185px" }}>
                                        <a href="#" className='text-center'>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center" style={{ height: "40px", width: "100%" }}>
                                                    <img width={"25%"} src="./catelogo/medical-support-service.png" alt="" />
                                                </div>

                                            </div>
                                            <p>
                                                Oxygen Ambulance
                                            </p>
                                        </a>
                                    </li>
                                    <li className='px-0' style={{ width: "185px" }}>
                                        <a href="#" className='text-center'>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center" style={{ height: "40px", width: "100%" }}>
                                                    <img width={"25%"} src="./catelogo/medical-support-service.png" alt="" />
                                                </div>

                                            </div>
                                            <p>
                                                Air Ambulance
                                            </p>
                                        </a>
                                    </li>
                                    <li className='px-0' style={{ width: "185px" }}>
                                        <a href="#" className='text-center'>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center" style={{ height: "40px", width: "100%" }}>
                                                    <img width={"25%"} src="./catelogo/medical-support-service.png" alt="" />
                                                </div>

                                            </div>
                                            <p>
                                                Mini Ambulance
                                            </p>
                                        </a>
                                    </li>
                                    <li className='px-0' style={{ width: "185px" }}>
                                        <a href="#" className='text-center'>
                                            <div className="single-features-style1">
                                                <div className="icon-holder text-center" style={{ height: "40px", width: "100%" }}>
                                                    <img width={"25%"} src="./catelogo/train-ambulance.png" alt="" />
                                                </div>

                                            </div>
                                            <p>
                                                Train Ambulance
                                            </p>
                                        </a>
                                    </li> */}





                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Services