import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'primereact/resources/themes/saga-blue/theme.css'; // or your preferred theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'; // For the icons
import { FaCircleArrowLeft } from "react-icons/fa6";
import { FaCircleArrowRight } from "react-icons/fa6";
import { RiWhatsappFill } from "react-icons/ri";
import { MdPhoneCallback } from "react-icons/md";
import 'animate.css';
import Section from './Pages/Section';
import Category from './Pages/Category';
import { FaWhatsapp } from "react-icons/fa";
import CustomerReview from './CustomerReview';
import Contact_us from './Pages/Contact_us';
import Contact_form from './Pages/Contact_form';
import Map from './Pages/Map';


const Home = () => {
    const sliderRef = useRef(null);
    let navigate = useNavigate()

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Hide default arrows to use custom ones
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    };
    // Function to go to the next slide
    const nextSlide = () => {
        sliderRef.current.slickNext();
    };
    // Function to go to the previous slide
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    var settings2 = {
        dots: false, // Show dots for navigation
        infinite: true, // Enable infinite looping
        speed: 500, // Transition speed for a smooth effect
        slidesToShow: 4, // Number of slides to show
        slidesToScroll: 1, // Number of slides to scroll at a time
        arrows: false, // Hide navigation arrows
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Delay between slides in milliseconds (3 seconds)
        cssEase: "linear", // Smooth sliding transition effect
        responsive: [
            {
                breakpoint: 1024, // Adjust settings for screens smaller than 1024px
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 600, // Adjust settings for screens smaller than 600px
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 480, // Adjust settings for screens smaller than 480px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    };


    const phoneNumber = "7002067082"; // Replace with your phone number
    const message = encodeURIComponent("Hello, I'm interested in your services!"); // Pre-filled message

    const handleWhatsAppRedirect = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        const whatsappLink = isMobile
            ? `whatsapp://send?phone=${phoneNumber}&text=${message}`
            : `https://wa.me/${phoneNumber}?text=${message}`;

        // Redirect to WhatsApp
        window.open(whatsappLink, "_blank", "noopener,noreferrer");
    };


    const [isVideoPlaying, setIsVideoPlaying] = useState(true);

    const handleVideoEnd = () => {
        setIsVideoPlaying(false); // Hide the video after it ends
    };


    return (
        <>

            <div className="boxed_wrapper ltr">
                <Section />
                <Category />




                {/*Start About Style1 Area*/}
                <section className="about-style1-area mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="features-style1__content">
                                    <div className="sec-title text-center">
                                        <div className="icon">
                                            <span className="icon-heartbeat" />
                                        </div>
                                        <h3 className=''>Why choose us?</h3>
                                    </div>
                                    <ul>
                                        <li className='col-4'>
                                            <div className="single-features-style1 ">

                                                <div className="text-holder text-center">
                                                    <h3><a href="#">100k+</a></h3>
                                                    <h6><a href="#">Lives Saved</a></h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='col-4'>
                                            <div className="single-features-style1 ">

                                                <div className="text-holder text-center">
                                                    <h3><a href="#">97%</a></h3>
                                                    <h6><a href="#"> Satisfaction Rate</a></h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='col-4'>
                                            <div className="single-features-style1 ">
                                                <div className="text-holder text-center">
                                                    <h3><a href="#">70+</a></h3>
                                                    <h6><a href="#">Partnered Hospitals</a></h6>
                                                </div>
                                            </div>
                                        </li>
                                        <li className='col-4'>
                                            <div className="single-features-style1 ">
                                                <div className="text-holder text-center">
                                                    <h3><a href="#">150+</a></h3>
                                                    <h6><a href="#">Fleet of Ambulances</a></h6>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row text-right-rtl">
                            <div className="col-xl-6">
                                <div className="about-style1__image clearfix">
                                    <div className="inner">
                                        <img src="./catelogo/about us ambuready-min.png" alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="about-style1__content pl-3 pr-3">
                                    <div className="sec-title">
                                        {/* <div className="sub-title">
                                            <h3>About Us</h3>
                                        </div> */}
                                        <h2>Ambuready - North East's<br /> Largest Ambulance Network</h2>
                                    </div>
                                    <div className="inner-content">
                                        <div className="text">
                                            <p style={{ textAlign: "justify" }}>Ambuready, boasting a fleet exceeding 150 plus ambulances and operational in all over north East, is committed to delivering swift and dependable medical aid. Our services encompass ambulance assistance, telemedicine, and more, ensuring comprehensive support for you and your dear ones throughout your healthcare voyage.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*End About Style1 Area*/}

                <button onClick={handleWhatsAppRedirect}
                    className="scroll-top scroll-to-target"
                    style={{ border: "1px solid green" }}
                //    style={{display:"block",bottom:"100px"}}
                >
                    <FaWhatsapp style={{ color: "green", fontSize: "35px" }} />
                </button>

                {/* <button
                    className="scroll-top scroll-to-target"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }}
                >
                    <span className="flaticon-up-arrow" />
                </button> */}

                {/* search-popup */}
                <div id="search-popup" className="search-popup">
                    <div className="close-search"><i className="icon-close" /></div>
                    <div className="popup-inner">
                        <div className="overlay-layer" />
                        <div className="search-form">
                            <form method="post" action="https://mehedi.asiandevelopers.com/ambons/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" defaultValue placeholder="Search Here" required />
                                        <input type="submit" defaultValue="Search Now!" className="theme-btn style-four" />
                                    </fieldset>
                                </div>
                            </form>
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><a href="index-2.html">waste</a></li>
                                <li><a href="index-2.html">Dumpster</a></li>
                                <li><a href="index-2.html">Zerowaste</a></li>
                                <li><a href="index-2.html">Garbage</a></li>
                                <li><a href="index-2.html">trash</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* search-popup end */}


                {/*Start Partner Area*/}
                {/* <section className="partner-area mt-5">
                    <div className="container pt-3">
                        <div className="brand-content">
                            <div className="inner">
                                <div className="sec-title text-center mb-2">
                                    <div className="icon">
                                        <span className="icon-heartbeat" />
                                    </div>
                                    <h4>OUR PARTNERED HOSPITALS</h4>
                                </div>
                                <ul className="pt-3 partner-box partner-carousel o owl-theme owl-dot-style1 rtl-carousel">
                                    
                                    <Slider  {...settings2}>
                                        <li className="single-partner-logo-box">
                                            <a style={{ display: "flex", justifyContent: "center" }} href="#"><img style={{ width: "30%" }} src="./img/appolo.png" alt="Awesome Image" /></a>
                                        </li>
                                        
                                        <li className="single-partner-logo-box">
                                            <a style={{ display: "flex", justifyContent: "center" }} href="#"><img style={{ width: "22%" }} src="./img/Dr._B._Borooah_Cancer_Institute_Logo_old.png" alt="Awesome Image" /></a>
                                        </li>
                                        
                                        <li className="single-partner-logo-box">
                                            <a style={{ display: "flex", justifyContent: "center" }} href="#"><img style={{ width: "75%" }} src="./img/gnrc.png" alt="Awesome Image" /></a>
                                        </li>
                                        
                                        <li className="single-partner-logo-box">
                                            <a style={{ display: "flex", justifyContent: "center" }} href="#"><img style={{ width: "38%" }} src="./img/hayat.jpg" alt="Awesome Image" /></a>
                                        </li>
                                    </Slider>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/*End Partner Area*/}

                <div className="sec-title text-center mb-2 ">
                    <div className="icon">
                        <span className="icon-heartbeat" />
                    </div>
                    <h3>Our Services</h3>
                </div>

                <section className="testimonial-style1-area">
                    {/* <div className="shape wow slideInRight" data-wow-delay="400ms" data-wow-duration="3500ms">
                        <img className="zoom-fade" src="assets/images/shape/shape-1.png" alt />
                    </div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">

                                <div className="testimonial-style1-content">
                                    {/* Custom navigation buttons */}
                                    <div style={{ textAlign: "right", marginTop: "10px" }}>
                                        <FaCircleArrowLeft
                                            style={{ fontSize: "25px", cursor: "pointer", marginRight: "10px", color: "var(--thm-base)", backgroundColor: "white", borderRadius: "15px" }}
                                            onClick={prevSlide}
                                        />
                                        <FaCircleArrowRight
                                            style={{ fontSize: "25px", cursor: "pointer", color: "var(--thm-base)", backgroundColor: "white", borderRadius: "15px" }}
                                            onClick={nextSlide}
                                        />
                                    </div>

                                    <div className="bg-color" />
                                    <Slider ref={sliderRef} {...settings}>
                                        <div className="theme_carousel testimonial-carousel owl-theme owl-nav-style-one">
                                            <div className="single-testimonial-style1">
                                                <div className="img-holder">
                                                    <img src="./services/1551214.jpg" alt />
                                                    <div className="overlay-content">
                                                        {/* <div className="quote-icon">
                                                            <img src="assets/images/icon/quote.png" alt />
                                                        </div> */}
                                                    </div>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-holder pl-3 pr-3">
                                                    <div className="sec-title sec-title--style2">
                                                        <div className="icon">
                                                            <span className="icon-heartbeat" />
                                                        </div>
                                                        <h2>Mini Ambulance Service</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p style={{ textAlign: "justify" }}>
                                                            Our mini ambulance services are perfect for delivering medical assistance in narrow or hard-to-reach areas where regular ambulances cannot go. These vehicles are equipped with essential medical equipment and staffed by trained medical professionals, allowing for a rapid response to emergencies in these locations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="theme_carousel testimonial-carousel owl-theme owl-nav-style-one">
                                            <div className="single-testimonial-style1">
                                                <div className="img-holder">
                                                    <img src="./services/NKAdCwN.jpg" alt />
                                                    <div className="overlay-content">
                                                        {/* <div className="quote-icon">
                                                            <img src="assets/images/icon/quote.png" alt />
                                                        </div> */}
                                                    </div>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-holder pl-3 pr-3">
                                                    <div className="sec-title sec-title--style2">
                                                        <div className="icon">
                                                            <span className="icon-heartbeat" />
                                                        </div>
                                                        <h2>Air Ambulance Service</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p style={{ textAlign: "justify" }}>
                                                            Our Air Ambulance Services offer swift emergency medical transport by aircraft. Outfitted with advanced medical facilities and staffed by highly trained professionals, they ensure the rapid transfer of critically ill or injured individuals to medical facilities, particularly in situations where ground transportation is impractical or time-sensitive.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="theme_carousel testimonial-carousel owl-theme owl-nav-style-one">
                                            <div className="single-testimonial-style1">
                                                <div className="img-holder">
                                                    <img src="./services/ambu11.jpg" alt />
                                                    <div className="overlay-content">
                                                        {/* <div className="quote-icon">
                                                            <img src="assets/images/icon/quote.png" alt />
                                                        </div> */}
                                                    </div>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-holder pl-3 pr-3">
                                                    <div className="sec-title sec-title--style2">
                                                        <div className="icon">
                                                            <span className="icon-heartbeat" />
                                                        </div>
                                                        <h2>Train Ambulance Service</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p style={{ textAlign: "justify" }}>
                                                            Our Train Ambulance Service provides rapid and efficient emergency medical transport. Staffed by skilled professionals and equipped with advanced medical technology, we ensure swift transfer of critically ill individuals to medical facilities. Our priority is timely response and specialized care, addressing the urgency of medical situations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="theme_carousel testimonial-carousel owl-theme owl-nav-style-one">
                                            <div className="single-testimonial-style1">
                                                <div className="img-holder">
                                                    <img src="./services/ambhu.jpg" alt />
                                                    <div className="overlay-content">
                                                        {/* <div className="quote-icon">
                                                            <img src="assets/images/icon/quote.png" alt />
                                                        </div> */}
                                                    </div>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-holder pl-3 pr-3">
                                                    <div className="sec-title sec-title--style2">
                                                        <div className="icon">
                                                            <span className="icon-heartbeat" />
                                                        </div>

                                                        <h2>Emergency Medical Transportation</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p style={{ textAlign: "justify" }}>
                                                            We prioritize swift and dependable emergency medical transportation for those requiring urgent care. Our ambulances are equipped with cutting-edge technology, ensuring top-tier care during transit. Rely on us for prompt, efficient, and expertly outfitted emergency medical services, customized to address your specific needs and concerns.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="theme_carousel testimonial-carousel owl-theme owl-nav-style-one">
                                            <div className="single-testimonial-style1">
                                                <div className="img-holder">
                                                    <img src="./services/ambulance-rescue-emergency-blue-light.jpg" alt />
                                                    <div className="overlay-content">
                                                        {/* <div className="quote-icon">
                                                            <img src="assets/images/icon/quote.png" alt />
                                                        </div> */}
                                                    </div>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-holder pl-3 pr-3">
                                                    <div className="sec-title sec-title--style2">
                                                        <div className="icon">
                                                            <span className="icon-heartbeat" />
                                                        </div>
                                                        <h2>Non-Emergency Medical Transportation</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p style={{ textAlign: "justify" }}>
                                                            Our non-emergency medical transportation services are designed for individuals requiring regular transport to medical appointments or between healthcare facilities. We focus on comfort, reliability, and punctuality to provide a stress-free experience. With trained staff and well-equipped vehicles, we ensure a safe and secure journey for all your medical transportation needs.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="theme_carousel testimonial-carousel owl-theme owl-nav-style-one">
                                            <div className="single-testimonial-style1">
                                                <div className="img-holder">
                                                    <img src="./services/Vgde8PO.jpg" alt />
                                                    <div className="overlay-content">
                                                        {/* <div className="quote-icon">
                                                            <img src="assets/images/icon/quote.png" alt />
                                                        </div> */}
                                                    </div>
                                                    <div className="review-box">
                                                        <ul>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                            <li><i className="fa fa-star" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="text-holder pl-3 pr-3">
                                                    <div className="sec-title sec-title--style2">
                                                        <div className="icon">
                                                            <span className="icon-heartbeat" />
                                                        </div>
                                                        <h2>Oxygen Cylinder-Ready Ambulance</h2>
                                                    </div>
                                                    <div className="text">
                                                        <p style={{ textAlign: "justify" }}>
                                                            Our oxygen-equipped ambulance services provide essential medical care for patients needing oxygen support during transport. Staffed by skilled medical professionals, we prioritize safety and comfort, delivering critical attention throughout the journey. Rely on us for dependable emergency services that ensure your well-being, addressing your specific medical needs with expertise.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Slider>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*Start slogan area*/}
                <section className="slogan-area" style={{ marginTop: "100px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-12">
                                <div className="slogan-img-box">
                                    <div className="inner wow slideInLeft" data-wow-delay="400ms" data-wow-duration="3500ms">
                                        <img className="float-bob-y" src="assets/images/resources/ambulance.png" alt />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-12">
                                <div className="slogan-text-box">
                                    <h2>Secure Immediate Ambulance Service - Contact Us Now</h2>
                                    {/* <h4>Call anytime <a href="tel:123456789">+ 1- (246) 333-0089</a></h4> */}
                                    <div className="slogan-btn-box mt-4">
                                        <a className="btn-one mb-1" onClick={handleWhatsAppRedirect}>
                                            <span style={{ color: "white" }} className="txt"> <RiWhatsappFill style={{ fontSize: "25px", color: "white" }} /> &nbsp; WhatsApp us +91 70020 67082</span>
                                        </a>
                                        <a className="btn-one mb-1 ml-2" href="tel:123456789" >
                                            <span style={{ color: "white" }} className="txt "> <MdPhoneCallback style={{ fontSize: "25px", color: "white" }} /> &nbsp;Call us +91 70020 67082</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*End slogan area*/}



                {/*Start  Contact Form Area*/}
                <Contact_form />
                {/*end  Contact Form Area*/}

                {/*Start  video Form Area*/}
                <section className="slogan-area" style={{ marginBottom: "20px" }}>
                    <div className="container" style={{ paddingTop: "12px" }}>
                        <video style={{ objectFit: "cover" }} className="w-100 __slide-img img-fluid" autoPlay
                            muted
                            loop>
                            <source className="w-100 __slide-img img-fluid" src="./services/servicevideo.mp4" type="video/mp4" />
                        </video>
                        <style>
                            {`
          video::-internal-media-controls-overlay-cast-button {
            display: none;
          }
          video::-webkit-media-controls-enclosure {
            display: none;
          }
          video::-webkit-media-controls-panel {
            display: none;
          }
        `}
                        </style>
                    </div>
                </section>
                {/*end  video Form Area*/}

                {/*start reaview section*/}
                <CustomerReview />
                {/*end reaview section*/}


            </div >
            {/* <Map /> */}
        </>
    )
}

export default Home