import React from 'react'
import { Link } from 'react-router-dom'

const Terms_Conditions = () => {
    return (
        <>
            <div className="boxed_wrapper ltr">
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url(catelogo/ambulance-rescue-emergency-blue-light.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <h2 className='text-white'>Terms and Conditions</h2>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="active">Terms Conditions</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-info-style2-area">
                    <div className="container">
                        <div className="row text-right-rtl">
                            <div className="mt-5">
                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <div className="text">
                                            <p>These terms and conditions, collectively referred to as the "Agreement," constitute a legal contract between you and Ambuready. By utilizing the mobile application provided by Ambuready ("the Application") and accessing any associated software ("the Software"), you acknowledge and agree to adhere to these terms and any future amendments published on the Ambuready website or through the Application. <br />
                                                This document is an electronic record as per the Information Technology Act, 2000, and the rules thereunder. It is published in compliance with the Information Technology (Intermediaries guidelines) Rules, 2011. <br />
                                                Ambuready acts as a technology aggregator and does not provide ambulance transportation services directly. It connects users with independent providers of ambulance transportation services ("Transportation Providers") through its Application and online portal. Ambuready is not responsible for the actions or omissions of Transportation Providers or the services they provide.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>1. Definitions</h4>
                                        <div className="text">
                                            <p>
                                                Account: The user account created on the Ambuready platform. <br />
                                                Advanced Life Support Ambulance (ALS): An ambulance equipped to handle life-threatening medical emergencies. <br />
                                                Ambulance: A registered vehicle used for transporting injured or patients. <br />
                                                Ambulance Service Zone: The geographical area served by ambulance services. <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>2. Eligibility</h4>
                                        <div className="text">
                                            <p>
                                                Users must be at least 18 years old and legally competent to use the service. Minors must be represented by a legal guardian or parent.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>3. Account & Registration Obligations</h4>
                                        <div className="text">
                                            <p>
                                                Users must provide accurate information during registration and maintain the confidentiality of their Account. Only one Account per user is permitted.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>4. Services</h4>
                                        <div className="text">
                                            <p>
                                                Ambuready offers various types of ambulance services including ALS, BLS, patient transport, and mortuary vehicle services.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>5. Confirmation of Booking</h4>
                                        <div className="text">
                                            <p>
                                                Booking confirmation is subject to ambulance availability and users must verify booking details promptly.
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>6. Payment</h4>
                                        <div className="text">
                                            <p>
                                                Payment can be made via cash or E-Wallet. Users are responsible for paying all applicable fees.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>7. Representation & Warranties</h4>
                                        <div className="text">
                                            <p>
                                                Users agree to use the service for lawful purposes and comply with all terms and conditions.
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>8. License</h4>
                                        <div className="text">
                                            <p>
                                                Users are granted a limited license to use the Application and related content for personal, non-commercial purposes.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>9. Disclaimers</h4>
                                        <div className="text">
                                            <p>
                                                Ambuready does not guarantee the accuracy or completeness of information provided by Transportation Providers. The service is provided "AS IS" without warranties.
                                            </p>
                                        </div>
                                    </div>
                                </div>




                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>10. Intellectual Property Ownership</h4>
                                        <div className="text">
                                            <p>
                                                Ambuready and its licensors own all rights to the Software and Application. Users are not granted ownership rights.
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>11. Liability</h4>
                                        <div className="text">
                                            <p>
                                                Ambuready's liability is limited to the total amount paid by the user. Ambuready is not liable for damages arising from the use of third-party services.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>12. Indemnity</h4>
                                        <div className="text">
                                            <p>
                                                Users agree to indemnify Ambuready against any claims arising from their use of the service.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>13. Severability</h4>
                                        <div className="text">
                                            <p>
                                                If any provision of the Agreement is found to be invalid, the remaining provisions shall remain in effect.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>14. Waiver</h4>
                                        <div className="text">
                                            <p>
                                                Any waiver of provisions does not constitute a continuing waiver of other provisions.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>15. Governing Laws & Jurisdiction</h4>
                                        <div className="text">
                                            <p>
                                                The Agreement is governed by Indian laws, and disputes are subject to the jurisdiction of Delhi courts.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>16. Privacy Policy</h4>
                                        <div className="text">
                                            <p>
                                                Users are encouraged to review Ambuready's Privacy Policy.
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>17. Communications</h4>
                                        <div className="text">
                                            <p>
                                                Users consent to receive communications from Ambuready via various channels.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>18. Use of Google Maps</h4>
                                        <div className="text">
                                            <p>
                                                Mapping services are provided by Google Maps, and users agree to comply with Google's Terms of Service.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Terms_Conditions