import React, { useEffect, useState } from 'react'
import { IoLocation } from 'react-icons/io5';
import { MdDashboard } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import { IoLogOut } from "react-icons/io5";

const Location_update = () => {
    let navigate = useNavigate()
    let logout_handel = () => {
        swal({
            title: "Are you sure?",
            text: "You want to logout",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            // content: {
            //     element: "input",
            //     attributes: {
            //         placeholder: "Enter your reason for deletion",
            //         type: "text",
            //     },
            // },
        }).then((willDelete) => {
            if (willDelete) {
                userlogout();
            } else {
            }
        });
        let userlogout = () => {
            navigate("/");

        };
    };


    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };

        // Initial check
        handleResize();

        // Add event listener for resizing
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <>
            <div className="boxed_wrapper ltr">
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url("assets/images/breadcrumb/breadcrumb-1.jpg")' }} />
                    <div className="container">

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <h2 className="text-white">Dashboard</h2>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/user_profile">User</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="service-details-area" style={{ paddingTop: "60px" }}>
                    <div className="container wow animate__animated animate__fadeIn">

                        {isSmallScreen ?
                            <div className='mb-3'>
                                <div className='row mb-5'>
                                    <div className='col-lg-6 col-4'>
                                        <div className="view-all-service">
                                            <ul className="text-center">
                                                <li ><Link to="/user_profile" >Profile </Link> </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-8'>
                                        <div className="view-all-service">
                                            <ul className="text-center">
                                                <li style={{ borderBottom: "2px solid red" }}><Link to="/location_update" >Update Location</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-form ">
                                    <form id="contact-form" name="contact_form" className="default-form2" action="https://mehedi.asiandevelopers.com/ambons/assets/inc/sendmail.php" method="post">
                                        <div className="form-group">
                                            <div className="input-box"><input type="text" name="form_name" id="formName" placeholder="Update Location Address" required autoComplete="off" /></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12 text-center">
                                                <div className="button-box"><input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" /><button className="btn-one" type="submit" data-loading-text="Please wait..."><span className="txt">
                                                    Update</span></button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            :


                            <>
                                <div className="row">
                                    <div className="col-xl-4 col-lg-5 mb-3">
                                        <div className="view-all-service">
                                            <ul className="service-pages">
                                                <li><Link to="/user_profile" >Profile <MdDashboard style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /></Link> </li>
                                                <li className='active'><Link to="/location_update" >Edit Location <IoLocation style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /> </Link></li>
                                                <li><a onClick={logout_handel}>Logout <IoLogOut style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className=" col-xl-8 col-lg-7 order-box-1">
                                        <section className="main-contact-form-area">
                                            <div className="container">
                                                <div className="contact-form">
                                                    <form id="contact-form" name="contact_form" className="default-form2" action="https://mehedi.asiandevelopers.com/ambons/assets/inc/sendmail.php" method="post">
                                                        <div className="form-group">
                                                            <div className="input-box"><input type="text" name="form_name" id="formName" placeholder="Update Location Address" required autoComplete="off" /></div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-12 text-center">
                                                                <div className="button-box"><input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" /><button className="btn-one" type="submit" data-loading-text="Please wait..."><span className="txt">
                                                                    Update</span></button></div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </>



                        }





                    </div>
                </section>
            </div>


        </>
    )
}

export default Location_update