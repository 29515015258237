import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Contact_form from './Contact_form';

const Contact_us = () => {
    return (
        <>
            <div className="boxed_wrapper ltr">
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url(catelogo/ambulance-rescue-emergency-blue-light.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <h2 className='text-white'>Contact - Us</h2>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li>Contact</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Contact_form />
            </div>
        </>
    )
}

export default Contact_us