import React, { useEffect, useRef, useState } from 'react'
import 'animate.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TfiArrowCircleLeft } from "react-icons/tfi";
import { TfiArrowCircleRight } from "react-icons/tfi";
import axios from 'axios';
import { Skeleton } from 'primereact/skeleton';
import { FaTruckFieldUn } from 'react-icons/fa6';

const Section = () => {
    const settings3 = {
        dots: false,
        // infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        afterChange: () => setAnimationKey(prevKey => prevKey + 1),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    };

    const sliderRef_banner = useRef(null);
    const [animationKey, setAnimationKey] = useState(0);
    let [bannerdata, setbannerdata] = useState([])
    let [skeleton, setskeleton] = useState(true)

    // Function to go to the next slide
    const nextSlide_banner = () => {
        sliderRef_banner.current.slickNext();
    };
    // Function to go to the previous slide
    const prevSlide_banner = () => {
        sliderRef_banner.current.slickPrev();
    };

    // get_banners handel
    useEffect(() => {
        setskeleton(false)
        axios
            .get(`${process.env.REACT_APP_API_KEY}get_banners`)
            .then((res) => {
                console.log("ressss", res)
                if (res.data.result == "true") {
                    setskeleton(true)
                    setbannerdata(res.data.data); // Adjusted to use correct data path
                } else {
                    setskeleton(false)
                }
            })
            .catch((error) => {
                console.log("error", error)
                setskeleton(false)
            });
    }, []);




    return (
        <>
            <div className="boxed_wrapper ltr">
                {/* Start Main Slider */}
                <section className="main-slider style1">
                    <div className="slider-box">
                        <div className="slider-contact-info-box wow animate__animated animate__backInRight" data-aos-duration={1500}>
                            <div className="slider-contact-info" style={{ animationDuration: '2s' }}>
                                <div className="icon">
                                    <div className="inner">
                                        <span className="icon-phone-call" />
                                    </div>
                                </div>
                                <div className="text">
                                    <h2><a href="tel:123456789">+91 70020 67082</a></h2>
                                    <p>Need help? Call anytime for<br /> ambulance services</p>
                                </div>
                            </div>
                        </div>
                        {/* Banner Carousel */}
                        <div style={{ position: "relative" }}>

                            {skeleton == true ? (
                                <>
                                    <Slider ref={sliderRef_banner} {...settings3}>
                                        {bannerdata?.map((items, index) => {

                                            return (
                                                <div key={index} className="banner-carousel owl-theme l" >
                                                    <div className="slide">
                                                        <div key={animationKey + 1} className="image-layer wow animate__animated animate__pulse" style={{ height: "90%", animationDuration: '5s', backgroundImage: `url(${process.env.REACT_APP_IMAGE}${items?.image})` }}>
                                                        </div>
                                                        <div className="auto-container">
                                                            <div className="content">
                                                                <div className="sub-title">
                                                                    <h3 key={animationKey + 1} className='wow animate__animated animate__fadeInDown'>Your Health is our Priority</h3>
                                                                </div>
                                                                <div className="">
                                                                    <h2 key={animationKey} className='mt-4 pb-4 wow animate__animated animate__zoomInDown' style={{ color: "white", fontSize: "40px", animationDuration: '1s' }}>
                                                                        {items?.title}
                                                                    </h2>
                                                                </div>
                                                                <div className="">
                                                                    <a key={animationKey + 1} className="btn-one wow animate__animated animate__fadeInUp mt-2" href="tel:+917002067082">
                                                                        <span style={{ color: "white" }} className="txt">
                                                                            Call<i className="icon-refresh arrow" />
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}


                                    </Slider>
                                    <div className="header-right" style={{ position: "absolute", top: "260px", right: "300px" }}>
                                        <div className="header-social-link">
                                            <ul className="clearfix">
                                                <li>
                                                    <a onClick={nextSlide_banner}><TfiArrowCircleLeft style={{ fontSize: "50px" }} /></a>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="header-right" style={{ position: "absolute", top: "320px", right: "300px" }}>
                                        <div className="header-social-link">
                                            <ul className="clearfix">
                                                <li>
                                                    <a onClick={prevSlide_banner}><TfiArrowCircleRight style={{ fontSize: "50px" }} /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="" style={{ marginTop: "60px" }}>
                                    <Skeleton width="100%" height="30rem"></Skeleton>
                                </div>

                            )}





                        </div>


                    </div>
                </section>
                {/* End Main Slider */}

            </div>
        </>
    )
}

export default Section