
import React from 'react';
import { Skeleton } from 'primereact/skeleton';

export default function Hello() {
    return (
        <div className="" style={{ marginTop: "60px" }}>
            <Skeleton width="100%" height="30rem"></Skeleton>
        </div>
    );
}
