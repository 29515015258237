import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

const Userlogin = () => {
    let navigate = useNavigate();
    let [loader, setloader] = useState(false)

    let userloginhadnel = (e) => {
        e.preventDefault(); // Fix the typo here
        let form = e.target
        let formdata = new FormData(form)
        setloader(true)

        axios.post(`${process.env.REACT_APP_API_KEY}Login`, formdata).then((res) => {
            if (res.data.result == "true") {
                setloader(false)
                secureLocalStorage.setItem("User_Id", res?.data.data?.id)
                toast.success(res.data.msg);
                setTimeout(() => {
                    navigate("/user_profile"); // Navigate after showing the toast
                }, 1500);
            } else {
                toast.error(res.data.msg);
                setloader(false)
            }
        }).catch((error) => {
            toast.error("Something Went Wrong");
            setloader(false)
        })

    };

    return (
        <>
            <Toaster />
            <section className="contact-info-style1-area mt-5">
                <div className="container pt-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-6">
                            <div className="form-style1-box">
                                <div className="top-title text-center">
                                    <h3>User-Login</h3>
                                </div>
                                <form onSubmit={userloginhadnel} className="default-form1">
                                    <div className="input-box">
                                        <input type="text" name="driver_mobile" placeholder="Number" required />
                                    </div>
                                    <div className="input-box">
                                        <input type="text" name="password" placeholder="Password" required />
                                    </div>

                                    <div className="button-box text-center">

                                        {loader == true ? (
                                            <div class="spinner-border text-danger" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                <span class="sr-only"></span>
                                            </div>
                                        ) : (
                                            <button type="submit" className="btn-one">
                                                <span className="txt">Login</span>
                                            </button>
                                        )}

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Userlogin;
