import React from 'react'
import { Link } from 'react-router-dom'

const Privacy = () => {
    return (
        <>
            <div className="boxed_wrapper ltr">
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url(catelogo/ambulance-rescue-emergency-blue-light.jpg)' }}>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <h2 className='text-white'>Privacy Policy</h2>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li className="active">Privacy Policy</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-info-style2-area">
                    <div className="container">
                        <div className="row text-right-rtl">
                            <div className="mt-5">
                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>1.1 REFUNDS</h4>
                                        <div className="text">
                                            <p>Your privacy is important to Ambuready ("Company," "we," "ambuready," or "us"). This Privacy Policy outlines how we collect, process, use, share, and protect your information. It also explains how you can access and update your information and make choices about its use.</p>
                                            <p>This Privacy Policy applies to both "online" (e.g., web and mobile services, including our website www.ambuready.com and Mobile Application) and "offline" (e.g., data collection through mail, telephone, or in person) activities conducted by the Company. Together, these online and offline activities related to facilitating transportation services through a network of third-party Ambulance Service Providers (ASPs) and/or drivers are referred to as the "Services." This policy also applies to your use of interactive features or downloads we own or control, or that are available through the Services.</p>
                                            <p>BY CLICKING THE ‘DONE’ BUTTON WHILE SIGNING UP WITH US, YOU AGREE TO OUR CUSTOMER TERMS AND CONDITIONS AND THE TERMS OF THIS PRIVACY POLICY. PLEASE READ THIS PRIVACY POLICY CAREFULLY. IF YOU DO NOT AGREE TO THIS POLICY, DO NOT USE OUR SERVICES OR MOBILE APPLICATION. THIS PRIVACY POLICY IS INCORPORATED BY REFERENCE INTO THE CUSTOMER TERMS AND CONDITIONS.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>1. DEFINITIONS</h4>
                                        <div className="text">
                                            <p>
                                                Unless defined otherwise in this Privacy Policy, the capitalized terms shall have the meanings as provided here and in the Terms and Conditions:  <br />
                                                “Co-branded Services” shall mean the services defined in paragraph 7(c). <br />
                                                “Device” refers to a computer, mobile, or other device used to access the Services. <br />
                                                “Device Identifier” means the IP address or other unique identifier for the Device. <br />
                                                “Mobile Application” refers to the ambuready application provided by us on the Device to access the Services. <br />
                                                “Mobile Device Information” has the meaning assigned in paragraph 2(d)(ii). <br />
                                                “Promotion” means any contests and other promotions offered by us. <br />
                                                “Protected Information” means information that could reasonably be used to identify you personally, including your name, email address, and mobile number. <br />
                                                “TPSP” stands for a third-party service provider. <br />
                                                “Usage Information” has the meaning assigned in paragraph 3.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>2. WHAT INFORMATION DO WE COLLECT?
                                            INFORMATION YOU PROVIDE TO US</h4>
                                        <div className="text">
                                            <p>
                                                We may ask you to provide certain Protected Information. This information may be collected through various means and places within the Services, including account registration forms, contact forms, or when you interact with us. When you sign up to use the Services, you create a user profile. We only request Protected Information necessary for lawful purposes connected with our Services. <br />
                                                The current data fields that might be requested are:
                                                Name <br />
                                                Address <br />
                                                Mobile phone number <br />
                                                Zip code <br />
                                                Relevant medical information (optional) <br />
                                                Providing medical information is optional. If you choose to share it, we will provide it to the Ambulance Service Provider to customize the ambulance for your medical emergency.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>3. INFORMATION WE COLLECT AS YOU ACCESS AND USE OUR SERVICES</h4>
                                        <div className="text">
                                            <p>
                                                In addition to Protected Information you submit to us, we and our TPSP may use technologies that automatically (or passively) collect/store certain information whenever you visit or interact with the Services (“Usage Information”). This Usage Information may include the browser you are using, the URL that referred you to our Services, the areas within our Services that you visit, and the time of day, among other information. We also collect your Device Identifier. A Device Identifier is a number assigned to your Device used to access the Services. <br />

                                                For bookings via call center, ambuready may record calls for quality and training purposes. <br />

                                                Tracking information is also collected as you navigate through our Services, including geographic areas. The driver's mobile phone will send your GPS coordinates during the ride to our servers. <br />

                                                Usage Information may be collected using a cookie. If you do not want information collected through cookies, your browser allows you to deny or accept their use. Disabling cookies may affect the functionality of the Services. The Company cannot control the use of cookies by third parties, and their use is not covered by our Privacy Policy. <br />

                                                You are also required to provide information about the purpose/intent of accessing our Services. Options include Advance Life Support (‘ALS’), Basic Life Support (‘BLS’), Patient Transport, and Mortuary on our Mobile Application and Website. You should choose the most appropriate option based on your needs. <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>4. INFORMATION THIRD PARTIES PROVIDE ABOUT YOU</h4>
                                        <div className="text">
                                            <p>
                                                We may supplement the information we collect about you with outside records from third parties from time to time.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>5. INFORMATION COLLECTED BY MOBILE APPLICATIONS</h4>
                                        <div className="text">
                                            <p>
                                                Our Services are primarily provided through the Mobile Application. We may collect and use technical data and related information, including technical information about your device, system, and application software, and peripherals, gathered periodically to facilitate software updates, product support, and other services to you. <br />

                                                When you use our Mobile Application, it may automatically collect and store some or all of the following information from your mobile device (“Mobile Device Information”): <br />

                                                Your preferred language and country site (if applicable) <br />
                                                The manufacturer and model of your mobile device <br />
                                                Your mobile operating system <br />
                                                The type of mobile internet browsers you are using <br />
                                                Your geolocation <br />
                                                Information about how you interact with the Mobile Application and any linked websites <br />
                                                Information to personalize services and content available through the Mobile Application <br />

                                                We do not collect confidential medical/personal information that needs protection under any Applicable Laws. We may ask for relevant medical information to serve you better, but providing it is not compulsory. Do not share any confidential medical/personal information on our Application/Website/Mobile Application or with any Ambulance Service Provider or their drivers. You take full responsibility if such information is leaked/made available. ambuready is not liable for such information being made available. <br />

                                                We understand that some laws require patient identity protection. We will comply with such laws to the best of our ability, but we are not liable for personal information stored during transit with any third-party service provider. If someone books an ambulance for another person, ambuready is not responsible if confidential information is misused or divulged internally or through the ASP/TPSP. <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>6. USE OF INFORMATION COLLECTED</h4>
                                        <div className="text">
                                            <p>
                                                Our primary goal in collecting your information is to enhance your experience with the Services. We use your information to monitor which features are used most, view your trip history, rate trips, and determine areas for improvement, including usage patterns and geographic locations to focus our services, features, and resources. <br />

                                                Based on the Protected Information you provide when registering, we will send you a welcoming email to verify your username and password. <br />

                                                We use the information from our Mobile Application to serve the correct app version depending on your device type, for troubleshooting, and for marketing purposes. <br />

                                                We use your IP address to help diagnose problems with our server and to administer our website(s). Your IP address is used to help identify you, but contains no personal information about you. <br />

                                                We will send you service-related announcements on rare occasions when necessary, such as maintenance notifications. If you do not wish to receive them, you can deactivate your account. <br />
                                                <br />
                                                We may use the information obtained to prevent, discover, and investigate violations of this Privacy Policy or any applicable terms of service, and to investigate fraud, chargeback, or other matters. <br />

                                                We provide some of your Protected Information (e.g., name, pick-up address, contact number) to the driver who accepts your transportation request so they can contact and find you. ASPs can access your Protected Information, including geo-location data. We may also include some medical information to suggest favorable ambulance options. <br />

                                                We use your geo-location information for various purposes, including viewing nearby drivers, setting your pick-up location, sending promotions and offers, and allowing you to share this information with others if you choose. <br />

                                                We use credit card or other payment information for billing purposes and to complete transactions.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>7. INFORMATION SHARING WITH THIRD PARTIES</h4>
                                        <div className="text">
                                            <p>
                                                ambuready does not sell, rent, or disclose your Protected Information to third parties without your consent, except as outlined in this Privacy Policy.
                                            </p>
                                        </div>
                                    </div>
                                </div>



                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>7. INFORMATION SHARING WITH THIRD PARTIES</h4>
                                        <div className="text">
                                            <p>
                                                ambuready does not sell, rent, or disclose your Protected Information to third parties without your consent, except as outlined in this Privacy Policy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sec-title">
                                        <h4>a. SERVICE PROVIDERS</h4>
                                        <div className="text">
                                            <p>
                                                We may provide Protected Information to our third-party service providers who assist us in operating and maintaining the Services. We will obtain assurances from these providers to safeguard the Protected Information disclosed to them.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sec-title">
                                        <h4>b. CO-BRANDED SERVICES</h4>
                                        <div className="text">
                                            <p>
                                                Some Services may be co-branded with a third party. If you register for such Services, both ambuready and the third party may receive your Protected Information. The third party's privacy policy may also apply.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="sec-title">
                                        <h4>c. OTHER DISCLOSURES</h4>
                                        <div className="text">
                                            <p>
                                                We may disclose your information: <br />

                                                To comply with laws, regulations, legal processes, or enforceable governmental requests <br />
                                                To enforce our Terms and Conditions or other agreements <br />
                                                To protect the rights, property, or safety of ambuready, our employees, our users, or others <br />
                                                In connection with a merger, acquisition, or sale of all or a portion of our assets <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>8. UPDATING AND ACCESSING YOUR INFORMATION</h4>
                                        <div className="text">
                                            <p>
                                                If you would like to update or access your information, you can log into your account or contact us at ambuready@gmail.com.
                                            </p>
                                        </div>
                                    </div>
                                </div>




                                <div className="contact-info-style1__content">
                                    <div className="sec-title">
                                        <h4>9. CHANGES TO THIS POLICY</h4>
                                        <div className="text">
                                            <p>
                                                We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make material changes, we will notify you by email (sent to the email address specified in your account) or through our Services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Privacy