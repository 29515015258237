import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

const Contact_form = () => {
    let [loader, setloader] = useState(false)
    let contactformhandel = (e) => {
        e.preventDefault();
        let form = e.target;
        let formdata = new FormData(form);
        setloader(true)
        axios.post(`${process.env.REACT_APP_API_KEY}add_contact`, formdata).then((res) => {
            if (res.data.result == "true") {
                setloader(false)
                toast.success(res.data.msg)
            } else {
                toast.error("Something Went Wrong")
                setloader(false)
            }
        }).catch((err) => {
            toast.error("Something Went Wrong")
            setloader(false)
        })
    };
    return (
        <>
            <Toaster />
            <div className="boxed_wrapper ltr">
                <section className="main-contact-form-area mt-5 pt-3">
                    <div className="container">
                        <div className="sec-title text-center">
                            <div className="icon">
                                <span className="icon-heartbeat" />
                            </div>
                            <div className="sub-title">
                                <h3>Send us Message</h3>
                            </div>
                            <h3>Join with north east largest ambulance network


                            </h3>
                        </div>
                        <div className="row mt-3">
                            <div className="col-xl-12">
                                <div className="contact-form">
                                    <form onSubmit={contactformhandel} className="default-form2" >
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <div className="input-box">
                                                        <input type="text" name="fullname" id="formName" required placeholder="Full Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <div className="input-box">
                                                        <input type="email" name="email" id="formEmail" placeholder="Email Address" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <div className="input-box">
                                                        <input type="text" name="phone" id="formPhone" required placeholder="Phone" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-group">
                                                    <div className="input-box">
                                                        <input type="text" name="subject" id="formSubject" placeholder="Subject" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <div className="input-box">
                                                        <textarea name="message" id="formMessage" placeholder="Write a Message" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 text-center">
                                                <div className="button-box">

                                                    {loader == true ? (
                                                        <div class="spinner-border text-danger" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                            <span class="sr-only"></span>
                                                        </div>
                                                    ) : (
                                                        <button className="btn-one" type="submit" data-loading-text="Please wait...">
                                                            <span className="txt">
                                                                send a message
                                                            </span>
                                                        </button>

                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div >
                </section>
            </div>
        </>
    )
}

export default Contact_form