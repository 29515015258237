import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoStarSharp } from "react-icons/io5";
import axios from 'axios';
import { Skeleton } from 'primereact/skeleton';

const CustomerReview = () => {
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    };

    let [reaviewdata, setreaviewdata] = useState([]);
    let [loaderstatus, setloaderstatus] = useState(true)
    const [expandedCardId, setExpandedCardId] = useState(null);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_KEY}get_rating_review`)
            .then((res) => {
                if (res.data.result === "true") {
                    setreaviewdata(res.data.data);
                    setloaderstatus(false)
                } else {
                    setloaderstatus(true)
                }
            })
            .catch((error) => {
                setloaderstatus(true)
                // console.error("Error fetching review data:", error);
            });
    }, []);

    // Generate stars based on rating
    const renderStars = (rating) => {
        const stars = Array.from({ length: rating }, (_, index) => (
            <IoStarSharp key={index} />
        ));
        return <div className="reviews" style={{ float: "right" }}>{stars}</div>;
    };

    const toggleReadMore = (id) => {
        setExpandedCardId((prevId) => (prevId === id ? null : id)); // Toggle the card
    };

    const stripTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    };


    return (
        <>
            <div className="sec-title text-center mb-2 mt-3">
                <div className="icon">
                    <span className="icon-heartbeat" />
                </div>
                <h3>Recent Reviews</h3>
            </div>


            {loaderstatus == true ? (
                <div className="col-xl-4 col-lg-4">
                    <div className="single-blog-style1">
                        <div className="text-holder" style={{ marginTop: "20px" }}>
                            <Skeleton width="100%" height="15rem"></Skeleton>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row'>
                    {/* <Slider {...settings2}>
                       </Slider> */}
                    {reaviewdata?.map((items, index) => {
                        console.log("items", items)
                        return (
                            <div key={index} className="col-xl-4 col-lg-4">
                                <div className="single-blog-style1">
                                    <div className="text-holder" style={{ marginTop: "20px" }}>
                                        <div className="meta-info">
                                            <p>
                                                <div className="btn-box">
                                                    <a>{items?.title}</a>
                                                    {renderStars(items?.rating)} {/* Dynamically render stars */}
                                                </div>
                                            </p>
                                        </div>


                                        <p>
                                            {expandedCardId === items.id
                                                ? stripTags(items.description)
                                                : `${stripTags(items.description?.slice(0, 120))}...`}
                                            <span
                                                style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
                                                onClick={() => toggleReadMore(items.id)}
                                            >
                                                {expandedCardId === items.id ? ' Read Less' : ' Read More'}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            )}

        </>
    );
};

export default CustomerReview;
