import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { MdDashboard } from "react-icons/md";
import { IoLocation, IoLogOut } from "react-icons/io5";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import WOW from 'wow.js';
import 'animate.css';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import swal from "sweetalert";
import Map from './Map';
import secureLocalStorage from 'react-secure-storage';

const User_profile = () => {
    let navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    let [userprofiledata, setuserprofiledata] = useState()
    let User_Id = secureLocalStorage.getItem("User_Id")
    let [loaderbtn, setloaderbtn] = useState(false)





    useEffect(() => {
        const wow = new WOW();
        wow.init();
    }, []);

    const handleTabSelect = (index) => {
        setSelectedTab(index); // Update selected tab index
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 992) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // get get_Profile hadnel
    useEffect(() => {
        let formdata = new FormData()
        formdata.append("driver_id", User_Id)
        axios.post(`${process.env.REACT_APP_API_KEY}get_Profile`, formdata).then((res) => {
            if (res.data.result == "true") {
                setuserprofiledata(res.data.data)
            }
        }).catch((err) => {
        })
    }, [])



    // log out handel
    let logout_handel = () => {
        swal({
            title: "Are you sure?",
            text: "You want to logout",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                userlogout();
            } else {
            }
        });
        let userlogout = () => {
            setTimeout(() => {
                secureLocalStorage.removeItem("User_Id")
                navigate("/");
            }, 500);
        };
    };


    // protect route
    useEffect(() => {
        const User_Id = secureLocalStorage.getItem("User_Id");
        if (!User_Id) {
            // If User_Id is missing, navigate to the home page
            navigate("/");
        }
    }, [navigate]);
    return (
        <>
            <Toaster />
            <div className="boxed_wrapper ltr">
                <section className="breadcrumb-area">
                    <div className="breadcrumb-area-bg" style={{ backgroundImage: 'url("assets/images/breadcrumb/breadcrumb-1.jpg")' }} />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content">
                                    <h2 className="text-white">Dashboard</h2>
                                    <div className="breadcrumb-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/user_profile">User</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="service-details-area" style={{ paddingTop: "40px" }}>
                    <div className="">
                        {isSmallScreen ?
                            <>
                                <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                                    <TabList className="tab-row">
                                        <Tab className="custom-tab" selectedClassName="custom-tab--selected">Profile</Tab>
                                        <Tab className="custom-tab" selectedClassName="custom-tab--selected">Update Location</Tab>
                                    </TabList>
                                    <TabPanel key={selectedTab === 0 ? 'panel1' : ''} className="container wow animate__animated animate__fadeIn">
                                        <div className="contact-form mt-3 mb-3">
                                            <form id="contact-form" name="contact_form" className="default-form2" action="https://mehedi.asiandevelopers.com/ambons/assets/inc/sendmail.php" method="post">
                                                <div className='row'>
                                                    <div className='col-sm-5 col-4'>
                                                        <div className='text-center mb-3'>
                                                            <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.ambu_owner_img}`} alt="Not Image Found" style={{ height: 120, borderRadius: 20, width: "100%" }} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-8 col-8'>
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Driver Name :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_phone" id="formPhone" disabled placeholder={userprofiledata?.driver_name} /></div>
                                                        </div>

                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Email :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_phone" id="formPhone" disabled placeholder={userprofiledata?.email} /></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xl-12 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Mobile Number:</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_name" id="formName" placeholder={userprofiledata?.driver_mobile} disabled required autoComplete="off" /></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Driver Email :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="email" name="form_email" id="formEmail" placeholder={userprofiledata?.driver_email} disabled required /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Ambulance Number :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_phone" id="formPhone" disabled placeholder={userprofiledata?.amb_no} /></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Drving License Number :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.driving_license} disabled /></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Ambulance Owner Name :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.amb_owner} disabled /></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Ambulance Contact Number :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.amb_contact_no} disabled /></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Vehical Number :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.vehicle_number} disabled /></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Experience :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.experience} disabled /></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Adderes :</h6>
                                                            <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.address} disabled /></div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className="col-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Driving License Image :</h6>
                                                            <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.driving_license_img}`} alt="Not Image Found" style={{ height: 120, borderRadius: 20, width: "100%" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Ambulance Owner Image :</h6>
                                                            <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.ambu_owner_img}`} alt="Not Image Found" style={{ height: 120, borderRadius: 20, width: "100%" }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row' >
                                                    <div className="col-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Passport Image :</h6>
                                                            <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.passport_image}`} alt="Not Image Found" style={{ height: 120, borderRadius: 20, width: "100%" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Id Proof Image :</h6>
                                                            <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.id_proof_image}`} alt="Not Image Found" style={{ height: 120, borderRadius: 20, width: "100%" }} />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <h6 className="sec-title mb-1">Ambulance Vegicle Image :</h6>
                                                            <img src="https://tse1.mm.bing.net/th?id=OIP.4jmfZG1ZmwWiOA95DhFRhgHaFg&pid=Api&rs=1&c=1&qlt=95&w=136&h=101 " alt="Not Image Found" style={{ height: 120, borderRadius: 20, width: "100%" }} />
                                                        </div>
                                                    </div>
                                                </div>


                                            </form>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-xl-12 text-center">
                                                <div className="button-box">
                                                    <button onClick={logout_handel} className="btn-one" type="submit" data-loading-text="Please wait..."><span className="txt">
                                                        Logout</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel key={selectedTab === 1 ? 'panel2' : ''} className="container wow animate__animated animate__fadeIn">
                                        <section className='mt-3 mb-3'>
                                            <div className="container">
                                                <div className="contact-form">
                                                    {/* <form onSubmit={updateloaditon_handel} className="default-form2" >
                                                    <div>
                                                        <IconField iconPosition="right" >
                                                            <InputIcon className="pi pi-map-marker" style={{ top: "15px" }}></InputIcon>
                                                            <InputText className='px-3 py-2' required placeholder="Update Location" style={{ width: "100%", fontSize: "16px", border: "1px solid gray" }} />
                                                        </IconField>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xl-12 text-center">
                                                            <div className="button-box">
                                                                {loaderbtn == true ? (
                                                                    <div class="spinner-border text-danger" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                        <span class="sr-only"></span>
                                                                    </div>
                                                                ) : (
                                                                    <button className="btn-one" type="submit" data-loading-text="Please wait..."><span className="txt">
                                                                        Update</span></button>

                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form> */}
                                                    <Map />

                                                </div>
                                            </div>
                                        </section>
                                    </TabPanel>
                                </Tabs>

                            </>
                            :
                            <>
                                <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-5 mb-4">
                                            <div className="view-all-service">
                                                <TabList style={{ border: "none" }}>
                                                    <ul className="service-pages">
                                                        <Tab style={{ border: "none" }}><li className='active' ><Link  >Profile <MdDashboard style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /></Link> </li></Tab>
                                                        <Tab style={{ border: "none" }}><Link >Update Location <IoLocation style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /> </Link></Tab>
                                                        <li style={{ paddingLeft: "12px", paddingRight: "12px" }}><a onClick={logout_handel}>Logout <IoLogOut style={{ fontSize: "20px", marginTop: "25px", float: "right" }} /></a></li>
                                                    </ul>
                                                </TabList>
                                            </div>
                                        </div>
                                        <div className=" col-xl-8 col-lg-7 order-box-1">
                                            <TabPanel key={selectedTab === 0 ? 'panel1' : ''} className="container wow animate__animated animate__fadeIn">
                                                <div className="contact-form mt-3 mb-3">
                                                    <form id="contact-form" name="contact_form" className="default-form2" action="https://mehedi.asiandevelopers.com/ambons/assets/inc/sendmail.php" method="post">
                                                        <div className='row'>
                                                            <div className='col-lg-4 col-sm-5 col-4'>
                                                                <div className='text-center mb-3'>
                                                                    <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.ambu_owner_img}`} alt="Not Image Found" style={{ height: 150, borderRadius: 20, width: "80%" }} />
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-8  col-sm-8 col-8'>
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Driver Name :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_phone" id="formPhone" disabled placeholder={userprofiledata?.driver_name} /></div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Email :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_phone" id="formPhone" disabled placeholder={userprofiledata?.email} /></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-12 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Mobile Number:</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_name" id="formName" placeholder={userprofiledata?.driver_mobile} disabled required autoComplete="off" /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-12 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Driver Email :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="email" name="form_email" id="formEmail" placeholder={userprofiledata?.driver_email} disabled required /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Ambulance Number :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_phone" id="formPhone" disabled placeholder={userprofiledata?.amb_no} /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Drving License Number :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.driving_license} disabled /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Ambulance Owner Name :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.amb_owner} disabled /></div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Ambulance Contact Number :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.amb_contact_no} disabled /></div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Vehical Number :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.vehicle_number} disabled /></div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Experience :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.experience} disabled /></div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-12 col-lg-12 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Adderes :</h6>
                                                                    <div className="input-box"><input style={{ height: "30px", paddingLeft: "5px", paddingRight: "5px" }} type="text" name="form_subject" id="formSubject" placeholder={userprofiledata?.address} disabled /></div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Driving License Image :</h6>
                                                                    <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.driving_license_img}`} alt="Not Image Found" style={{ height: 200, borderRadius: 20, width: "100%" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Ambulance Owner Image :</h6>
                                                                    <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.ambu_owner_img}`} alt="Not Image Found" style={{ height: 200, borderRadius: 20, width: "100%" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row' >
                                                            <div className="col-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Passport Image :</h6>
                                                                    <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.passport_image}`} alt="Not Image Found" style={{ height: 200, borderRadius: 20, width: "100%" }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 col-md-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Id Proof Image :</h6>
                                                                    <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.id_proof_image}`} alt="Not Image Found" style={{ height: 200, borderRadius: 20, width: "100%" }} />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <h6 className="sec-title mb-1">Ambulance Vegicle Image :</h6>
                                                                    <img src={`${process.env.REACT_APP_IMAGE}${userprofiledata?.vehicle_img}`} alt="Not Image Found" style={{ height: 200, borderRadius: 20, width: "100%" }} />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </form>
                                                </div>

                                            </TabPanel>
                                            <TabPanel key={selectedTab === 1 ? 'panel2' : ''} className="container wow animate__animated animate__fadeIn">
                                                <section className="main-contact-form-area mt-5">
                                                    <div className="container">
                                                        <div className="contact-form">
                                                            {/* <form onSubmit={updateloaditon_handel} className="default-form2" >

                                                                <div>
                                                                    <IconField iconPosition="right" >
                                                                        <InputIcon className="pi pi-map-marker" style={{ top: "15px" }}></InputIcon>
                                                                        <InputText className='px-3 py-2' required placeholder="Update Location" style={{ width: "100%", fontSize: "16px", border: "1px solid gray" }} />
                                                                    </IconField>
                                                                </div>

                                                                <div className="row mt-3">
                                                                    <div className="col-xl-12 text-center">
                                                                        <div className="button-box">
                                                                            {loaderbtn == true ? (
                                                                                <div class="spinner-border text-danger" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                                    <span class="sr-only"></span>
                                                                                </div>
                                                                            ) : (
                                                                                <button className="btn-one" type="submit" data-loading-text="Please wait..."><span className="txt">
                                                                                    Update</span></button>

                                                                            )}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form> */}
                                                            <Map />
                                                        </div>
                                                    </div>
                                                </section>
                                            </TabPanel>
                                        </div>
                                    </div>


                                </Tabs>
                            </>
                        }
                    </div>
                </section>
            </div>

        </>
    )
}

export default User_profile